export const couponLinkWienerStaedtische =
  "https://besserleben.wienerstaedtische.at/de/edition/vorsorge/mediclass-gesundheitsclub";

export const additionalPreventiveCareFee = 88;
export const preventiveCareBasicPrice = 240;
export const preventiveCarePrice = 320;
export const officialPreventiveCarePrice = 590;

export const labBookingName = "Labortermin";
export const healthProfessionalBookingName = "Untersuchungstermin";

export const locationId = 2;
